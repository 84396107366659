import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { ThemeContext } from "../contexts/ThemeContext";
import { UserContext } from "../contexts/UserContext";
import { useJobId } from "../contexts/ContextJbo";
import Banner from "./HomePageBlocks/Banner";
import Collage from "./HomePageBlocks/Collage";
import { AnimatePresence } from "framer-motion";
import InfoSlider from "./HomePageBlocks/InfoSlider";
import ProductSlider from "./HomePageBlocks/ProductSlider";
import CategorySlider from "./HomePageBlocks/CategorySlider";
import Subscription from "./HomePageBlocks/Subscription";
import CustomSection from "./HomePageBlocks/CustomSection";
import LoadingAnimation from "../components/custom/LoadingAnimation";
import SEO from "../components/custom/SEO";
import { BUILDER_URL } from "../utils/config";
import useTracking from "../components/custom/useTracking";
const HomePage = () => {
  const [widgets, setWidgets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoadStatus, setImageLoadStatus] = useState({});
  const theme = useContext(ThemeContext);
  const { setDomainData } = useContext(UserContext);
  const [SEOData, setSEOData] = useState({});
  const { jboId } = useJobId();
  const trackPage = useTracking("home");
  const rootRef = useRef(null);

  const fetchWidgets = async () => {
    try {
      const response = await axios.get(`${BUILDER_URL}/widget-order`, {
        headers: { Authorization: `Bearer ${theme.token}` },
      });
      const sortedWidgets = response.data.sort(
        (a, b) => a.order_by - b.order_by
      );
      setWidgets(sortedWidgets);
    } catch (error) {
      console.error("Error fetching widgets:", error);
      logError("widget-order", error);
    }
  };

  const fetchSeoData = async () => {
    try {
      const response = await axios.get(`${BUILDER_URL}/Seo-settings`, {
        headers: { Authorization: `Bearer ${theme.token}` },
      });
      const data = response.data[0];
      const seoKeywords = data.seo_keywords.join(",");
      setDomainData({ seo_domain_name: data.seo_domain_name });
      setSEOData({
        domainName: data.seo_domain_name,
        title: data.seo_title,
        description: data.seo_description,
        keyword: seoKeywords,
      });
      const storedLogoUrl = sessionStorage.getItem("logoUrl");
      const scriptElementSeo = document.createElement("script");
      scriptElementSeo.type = "application/ld+json";
      scriptElementSeo.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        name: data.seo_domain_name,
        url: window.location.href,
        logo: storedLogoUrl,
      });
      document.head.appendChild(scriptElementSeo);
    } catch (error) {
      console.error("Error fetching SEO data:", error);
      logError("/Seo-settings", error);
    }
  };

  const loadContent = async () => {
    await Promise.all([fetchWidgets(), fetchSeoData()]);
  };

  useEffect(() => {
    // const logError = (endpoint, error) => {
    //   const responseData = error.response?.data ?? "";
    //   errorLogApi({
    //     job_id: jboId,
    //     endpoint: endpoint,
    //     payload: "",
    //     responseData: responseData,
    //     errorMsg: error,
    //   });
    // };

    loadContent()
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
      trackPage();
  }, [theme.token, jboId]);

  const handleImageLoad = (widgetId) => {
    setImageLoadStatus((prevStatus) => ({
      ...prevStatus,
      [widgetId]: true,
    }));
  };

 
  return (
    
      <div ref={rootRef}>
        <SEO
          title={SEOData?.title}
          description={SEOData?.description}
          keyword={SEOData?.keyword}
        />
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          
            <div>
            {widgets.map((widget) => {
              const widgetComponent = (() => {
                switch (widget.widget_type) {
                  case "Banner":
                    return (
                      <Banner
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Collage":
                    return (
                      <Collage
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Info Slider":
                    return (
                      <InfoSlider
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Product Slider":
                    return (
                      <ProductSlider
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Category Slider":
                    return (
                      <CategorySlider
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Subscription":
                    return (
                      <Subscription
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  case "Cms Custom":
                    return (
                      <CustomSection
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    );
                  default:
                    return null;
                }
              })();

              return widgetComponent
                
    
            })}
          </div>
            
          
        )}
      </div>
   
  );
};

export default HomePage;
