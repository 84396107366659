import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Link from '../../components/custom/Link';
import { BUILDER_URL, setImageSrc } from '../../utils/config';
import * as Sentry from "@sentry/react";
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import Image from 'next/image';
const BASE_URL = BUILDER_URL;

const Banner = ({ widgetId, onImageLoad }) => {
  const [bannerData, setBannerData] = useState(null);
  const theme = useContext(ThemeContext);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [imageHeight, setImageHeight] = useState(null);

  const handleWindowResize = () => {
    setViewportWidth(window.innerWidth);
  };

  const CarouselPlaceholder = () => {
    return (
      <div className="w-full flex justify-center items-center">
        <LoadingAnimation />
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (widgetId) {
      axios
        .get(`${BASE_URL}/custom-banner/${widgetId}`, {
          headers: {
            'Authorization': `Bearer ${theme.token}`
          }
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setBannerData(response.data[0]);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }, [widgetId]);

  if (!bannerData) return <CarouselPlaceholder />;

  const aspectRatioPercent = (9 / 16) * 100;

  let imagesToShow = [];
  let imageClass = "w-full";
  let videoClass = "w-full";

  if (viewportWidth >= 1024 && viewportWidth <= 2440) {
    imagesToShow = bannerData.computer_view_image;
    imageClass = "";
    videoClass = "w-[100%] h-[600px] lg:h-[480px] md:h-[400px] xl:h-[700px] sm:h-[800px]  object-fit";
  } else if (viewportWidth >= 768 && viewportWidth < 1024) {
    imagesToShow = bannerData.tab_view_image;
    imageClass = "";
    videoClass = "w-[100%] h-[600px] lg:h-[500px] md:h-[400px] xl:h-[700px] sm:h-[800px]  object-fit";
  } else if (viewportWidth < 640) {
    imagesToShow = bannerData.mobile_view_image;
  }

  let carouselItems = [];

  if (bannerData.is_video_enabled) {
    const videoUrl = new URL(bannerData.video_link);
    let embedCode;

    if (videoUrl.host === 'youtu.be' || videoUrl.host.includes('youtube.com')) {
      let videoId;
      if (videoUrl.host === 'youtu.be') {
        videoId = videoUrl.pathname.split('/')[1];
      } else {
        videoId = videoUrl.searchParams.get('v');
      }

      if (videoId) {
        embedCode = (
          <iframe
            className={videoClass}
            height={imageHeight || 315}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&modestbranding=1&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            playsInline
            title="Embedded YouTube Video"
          ></iframe>
        );
      }
    } else {
      embedCode = (
        <video
          className={videoClass}
          height={imageHeight || 315}
          controls
          autoplay
          muted
          playsInline
          loop
          title="Direct Video Link"
        >
          <source src={bannerData.video_link} type={`video/${videoUrl.pathname.split('.').pop()}`} />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (embedCode) {
      carouselItems.push(
        <div key="video" style={{ position: 'relative' }}>
          {embedCode}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              pointerEvents: 'none',
            }}
          ></div>
        </div>
      );
    } else {
      console.error('Invalid video link');
    }
  }

  carouselItems = carouselItems.concat(
    imagesToShow.map((imageObj, index) => (
      <Link key={index} href={imageObj.image_link ? imageObj.image_link : '#'} className="flex justify-center items-center">
         <Image
          className={imageClass}
          src={setImageSrc(imageObj.image_url, '')}
          onLoad={onImageLoad}
          alt={`View for viewport width ${viewportWidth}`}
          layout="intrinsic" // Use 'intrinsic' or 'fixed'
          width={1200} // Set the desired width
          height={500} // Set the desired height
          objectFit="cover" // Adjust as necessary
          priority // Add this if you want to preload the image
        />
      </Link>
    ))
  );

  return (
    <div className="lg:mb-6 mb-2">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        interval={bannerData.banner_rotation * 1000}
      >
        {carouselItems}
      </Carousel>
    </div>
  );
};

export default Banner;
