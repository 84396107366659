import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import axios from "axios";
import ProductComponent from "../../components/ProductsGrid/RelatedProducts";
// import RelatedAndSimilar from "../../components/ProductsGrid/RelatedAndSimilar";
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import { BUILDER_URL } from '../../utils/config';
import * as Sentry from "@sentry/react";
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
// import { UserContext } from "../../contexts/UserContext";

// Base API Endpoint
const BASE_URL = BUILDER_URL;

const ProductSlider = ({ widgetId }) => {
  const theme = useContext(ThemeContext); // Access Theme Context
  const { jboId } = useJobId();
  const [sliderConfig, setSliderConfig] = useState({
    products: null,
    sliderHeader: '',
    numberOfProducts: 0,
    isProductNameVisible: '',
    isPriceVisible: '',
    isSKUVisible: '',
    rotationSeconds: 0
  });
  const [responseData, setResponseData] = useState('');
  // const {setProductSliderConfig} = useContext(UserContext); 

  const handleImageLoaded = (event) => {
    // Directly hide the LoadingAnimation sibling of the img tag
    event.currentTarget.previousSibling.style.display = 'none';
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/product-slider/${widgetId}`, {
          headers: {
            'Authorization': `Bearer ${theme.token}`
          }
        });
        const { automatic_product, custom_product, slider_header, number_of_products, is_product_name, is_price, is_sku, rotation_seconds, widget_title } = response.data;
        setResponseData(response.data);
        if (automatic_product.length > 0 || custom_product.length > 0) {
          const productResponse = await axios.get(`${BASE_URL}/product-slider/preview/${widgetId}`, {
            headers: {
              'Authorization': `Bearer ${theme.token}`
            }
          });

          if (productResponse.data && productResponse.data.length > 0) {
            

            const transformedData = productResponse.data.map(product => ({
              ...product,
              id: product.product_id
            }));
            setSliderConfig({
              products: transformedData,
              sliderHeader: widget_title,
              numberOfProducts: number_of_products,
              isProductNameVisible: is_product_name,
              isPriceVisible: is_price,
              isSKUVisible: is_sku,
              rotationSeconds: rotation_seconds
            });
            setIsLoading(false);
          }
        }
      } catch (error) {
        const jboID = jboId;
        const endpoint = `product-slider/${widgetId}`;
        const payLoad = '';
        const responseData = error?.response?.data ? error.response.data : '';
        const errorMsg = error;
        errorLogApi({
          job_id: jboID,
          endpoint: endpoint,
          payload: payLoad,
          responseData: responseData,
          errorMsg: errorMsg
        });

        setResponseData(null);
        Sentry.captureException(error);
        setIsLoading(false);
      }
      //  finally {
      //   onApiComplete();
      //   // setIsLoading(false);
      // }
    };

    if (widgetId) {
      fetchData();
    }
  }, [widgetId, theme.token]);

  if (!responseData) return null;

  return (
    <div className="block xl:container px-4 pb-8">
      <span className='text-xl lg:text-2xl px-3 lg:px-0 pb-2 lg:pb-8 font-semibold text-primary flex justify-center '>{sliderConfig.sliderHeader}</span>
      {/* <RelatedAndSimilar products={sliderConfig.products} sliderConfigData={sliderConfig}  isLoading={isLoading} isSpecificGrid={false} /> */}
      <ProductComponent products={sliderConfig.products} sliderConfigData={sliderConfig}  isLoading={isLoading} isSpecificGrid={false} />
    </div>
  );
}

export default ProductSlider;
