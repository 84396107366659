import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import { BUILDER_URL } from '../../utils/config';
import "suneditor/dist/css/suneditor.min.css";
const BASE_URL = BUILDER_URL;
import * as Sentry from "@sentry/react";
import root from 'react-shadow'
/**
 * CustomSection component to fetch and display custom content based on a widget ID.
 *
 * This component uses a widget ID to fetch custom content from a CMS and displays it.
 * It handles loading states and renders the fetched content.
 *
 * @param {string} widgetId - The ID of the widget for fetching custom content.
 */
const CustomSection = ({ widgetId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useContext(ThemeContext);
  // Fetching custom content from the API
  useEffect(() => {
    if (widgetId) {
      axios
        .get(`${BASE_URL}/cms-custom/${widgetId}`, {
          headers: { Authorization: `Bearer ${theme.token}` },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
         
            setData(response.data[0]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setData(null);
          setIsLoading(false);
          Sentry.captureException(error);
        })
      // .finally(() => {
      //   onApiComplete(); // This marks the API call as completed
      // });
    }
  }, [widgetId, theme]);

  if (!data) return null;

  // Display a loading indicator while the data is being fetched
  if (isLoading) return <div className="text-center py-4"></div>;
  // JSX for rendering the custom section content
  return (
    <>

      <span className="text-xl lg:text-2xl pb-1 lg:pb-4 text-center px-3 lg:px-0 font-semibold text-primary flex justify-center">
        {data.widget_title}
      </span>

      <div className="block xl:container w-full mx-auto px-4 py-6 bg-white  rounded-md">
      {
        data.is_html_tag == 1 ? (  
        <root.div>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        />
        <div
          className=""
          style={{ maxWidth: "100%" }}
          dangerouslySetInnerHTML={{ __html: data.html_editor }}
        ></div>
      </root.div>) : (
       <div
       className="prose  prose-sm sm:prose lg:prose-lg mx-auto  sun-editor-editable"
       style={{ maxWidth: "100%" }}
       dangerouslySetInnerHTML={{ __html: data.cms_custom_editor }}
     ></div> )

      }
       
      </div>

    </>

  );
};

export default CustomSection;
